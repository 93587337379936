import React from 'react'
import '../styles/testimonyStyles.scss';



export const Testimony = () => {
  
    return (
        <>
<div className=" bg-gray-200 flex flex-col items-center h-full py-10 justify-around"> 
        <h1 className="testimony_box_heading">Testimonials</h1>
        <div
        className="max-w-5xl flex flex-row items-center flex-wrap justify-around"
      >
        <div className="testimony bg-gray-200 shadow-sm max-w-md h-full border border-solid p-8 ">
                        <p className="testimony_p text-base text-gray-800">"It was creatively curated to
                            exhibit how the law can interact with tech and how leveraging the latter facilitates
                            access to information as was seen from the various products that were launched by
                            Bytelex. More so, it was encouraging to hear stories of home grown startups and the
                            legal intricacies involved in acquiring seed funding."
                        </p>
        
                    <div className="test_person mt-5">
                        <div className="name font-bold">Brain Alex Kira</div>
                        <div className="designation text-gray-600">Lawyer</div>
                    </div>
        </div>

        <div className="testimony bg-gray-200 shadow-sm max-w-md h-full border border-solid p-8 ">
                        <p className="testimony_p text-base text-gray-800">"As an investor, my major take from last year&#39;s Next.Legal Summit is that with this event,
                            Bytelex is filling the gap between the innovators as founders and the capital markets that spur
                            growth. It is amazing to watch the impact this event is having on young innovators and the
                            future of the start-up ecosystem in this country."
                        </p>
        
                    <div className="test_person mt-5">
                        <div className="name font-bold">Mary Adoi-Legal</div>
                        <div className="designation text-gray-600">Counsel, RENEW</div>
                    </div>

        </div>

        <div className="testimony bg-gray-200 shadow-sm max-w-md h-full border border-solid p-8 ">
                        <p className="testimony_p text-base text-gray-800">" One gets to hear from founders who have
                            overcome obstacles, meet fellow founders and most importantly, understand the legal past,
                            present and future of tech in Uganda and Africa."
                        </p>
        
                    <div className="test_person mt-5">
                        <div className="name font-bold">Muduuli David Serge</div>
                        <div className="designation text-gray-600">Founder, Omni Gym</div>
                    </div>

        </div>
</div>
</div>


        </>

        
    )
}
 