import React from 'react'
import '../styles/eventspeakerStyles.scss'

export const Speakers = () => {
    return (
        <div className="event-speaker-container">
                <div className="event-speakers">
                      <h2 className="speakers-title">Speakers</h2>
                   <div className="speaker-box">
                      <div className="event-speaker">
                            <img className="event-speaker-img" src="https://res.cloudinary.com/mfanolab-design/image/upload/fl_progressive:steep/v1601636365/next-legal/Samuel_Kitara_qp7h8i.jpg" alt="speaker-image"/>
                            <h3 className="event-speaker-name">Samuel Kitara</h3>
                            <h3 className="event-speaker-company text-gray-600">Founder, Yale Startup Review</h3>
                      </div>
                      <div className="event-speaker">
                            <img className="event-speaker-img" src="https://res.cloudinary.com/mfanolab-design/image/upload/fl_progressive:steep/v1601636365/next-legal/Olivia_Zank_gc6mr3.jpg" alt="speaker-image"/>
                            <h3 className="event-speaker-name">Ms Olivia Zank</h3>
                            <h3 className="event-speaker-company text-gray-600">Founder & CEO, BeneFactors</h3>
                      </div>
                      <div className="event-speaker">
                            <img className="event-speaker-img" src="https://res.cloudinary.com/mfanolab-design/image/upload/fl_progressive:steep/v1601636365/next-legal/Shimanyi__copy_fqdlcl.jpg" alt="speaker-image"/>
                            <h3 className="event-speaker-name">Valentino Shimanyi</h3>
                            <h3 className="event-speaker-company text-gray-600">CEO, Sisitech Ltd</h3>
                      </div>
                      <div className="event-speaker">
                            <img className="event-speaker-img" src="https://res.cloudinary.com/mfanolab-design/image/upload/fl_progressive:steep/v1601636365/next-legal/dickson_mushabe_i4hwio.jpg" alt="speaker-image"/>
                            <h3 className="event-speaker-name">Dickson Mushabe</h3>
                            <h3 className="event-speaker-company text-gray-600">CEO, Hostalite</h3>
                      </div>
                      <div className="event-speaker">
                            <img className="event-speaker-img" src="https://res.cloudinary.com/mfanolab-design/image/upload/fl_progressive:steep/v1601636366/next-legal/Favour_Chananel_Ruhiu_v7jfq3.png" alt="speaker-image"/>
                            <h3 className="event-speaker-name">Favour Chananel Ruhiu</h3>
                            <h3 className="event-speaker-company text-gray-600">Business Development Director, Soofa Pay</h3>
                      </div>
                      <div className="event-speaker">
                            <img className="event-speaker-img" src="https://res.cloudinary.com/mfanolab-design/image/upload/fl_progressive:steep/v1601636365/next-legal/Larry_yon_l8i3jz.jpg" alt="speaker-image"/>
                            <h3 className="event-speaker-name">Larry Yon</h3>
                            <h3 className="event-speaker-company text-gray-600">Senior Partner & COO, B&C International Inc</h3>
                      </div>
                      <div className="event-speaker">
                            <img className="event-speaker-img" src="https://res.cloudinary.com/mfanolab-design/image/upload/fl_progressive:steep/v1603122505/next-legal/dorna_moini_tvuiri.jpg" alt="speaker-image"/>
                            <h3 className="event-speaker-name">Dorna Moini</h3>
                            <h3 className="event-speaker-company text-gray-600"> - CEO & Co-Founder, Documate</h3>
                      </div>
                      <div className="event-speaker">
                            <img className="event-speaker-img" src="https://res.cloudinary.com/mfanolab-design/image/upload/fl_progressive:steep/v1603122504/next-legal/jared_qbbq8z.jpg" alt="speaker-image"/>
                            <h3 className="event-speaker-name">Jared Correia</h3>
                            <h3 className="event-speaker-company text-gray-600">Founder & CEO, Red Cave Law Firm Consulting</h3>
                      </div>
                      <div className="event-speaker">
                            <img className="event-speaker-img" src="https://res.cloudinary.com/mfanolab-design/image/upload/fl_progressive:steep/v1603122504/next-legal/ben_lyon_fkzxny.jpg" alt="speaker-image"/>
                            <h3 className="event-speaker-name">Ben Lyon</h3>
                            <h3 className="event-speaker-company text-gray-600">Co-Founder & CEO, Hoover</h3>
                      </div>
                      <div className="event-speaker">
                            <img className="event-speaker-img" src="https://res.cloudinary.com/mfanolab-design/image/upload/fl_progressive:steep/v1603184006/next-legal/Abu_bakr_Cassim_ayqnjm.jpg" alt="speaker-image"/>
                            <h3 className="event-speaker-name">Abu Cassim</h3>
                            <h3 className="event-speaker-company text-gray-600">Jozi Angels</h3>
                      </div>
                   </div>
              </div>
        </div>
    )
}
